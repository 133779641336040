import React, { useContext, useEffect, useState } from "react";
import HyperLogo from "../../Images/HyperLogo.png";
import styled from "styled-components";
import EFLClock from "./EFLClock";
import EFLPlayer from "./EFLPlayer";
import Subs from "../Subs/Subs";
import EndHalf from "./EndHalf";
import moment from "moment";
import { Button, ScrollBar } from "../../Theme/Hyper";
import { UserContext } from "../../contexts/UserContext";
import Select from "react-select";
import { ControlContext } from "../../contexts/ControlContext";
import { APIContext } from "../../contexts/APIContext";
import { UIContext } from "../../contexts/UIContext";
import { GameEventsContext } from "../../contexts/GameEventContext";

const Scroll = styled(ScrollBar)`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 44vh;
  width: 100%;
  align-items: center;
`;
const Main = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 0.5em;
  background-color: #202731;
  box-sizing: border-box;
  flex-direction: column;
  position: relative;
`;
const Logo = styled.img`
  height: auto;
  width: 50%;
`;
const GraphicButton = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.green ? "#0adb93" : props.red ? "#db0a41" : "#181d25"};
  color: ${(props) => (props.green ? "#1d1d1d" : "")};
  min-height: 50px;
  width: 90%;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 1em;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: ${(props) =>
      props.green ? "#0adb93" : props.red ? "#db0a41" : "#db0a41"};
  }
`;
const CurrentGraphic = styled.div`
  margin-top: auto;
  width: 100%;
  height: 167px;
  background-color: #181d25;
  box-sizing: border-box;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 0.5em;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 12px;
  margin: 0px;
  padding-bottom: 10px;
`;
const Buttons = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;
const NavButton = styled(Button)`
  margin: 0.5em;
  background-color: ${(props) => (props.selected ? "#0adb93" : "")};
  color: ${(props) => (props.selected ? "#1d1d1d" : "")};
`;

const Profile = styled.div`
  width: 100%;
  height: 35px;
  background-color: #202731;
  z-index: 1000;
  position: -webkit-sticky;
  position: sticky;
  box-shadow: 0px 4px 12px -20px rgb(0 0 0 / 20%),
    0px 8px 20px 2px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
  display: flex;
  align-items: center;
  border-bottom: 1px solid #030304;
  padding-left: 5px;
  padding-right: 5px;
  box-sizing: border-box;
  padding-bottom: 5px;
  margin-bottom: 10px;
`;

const SelectContainer = styled(Select)`
  min-width: 200px;
  margin-bottom: 0.5em;
  width: 90%;
`;

const DropdownStyles = {
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#0e1219",
  }),
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: "#0e1219",
      "&:hover": {
        color: "#db0a41",
      },
    };
  },
  control: (provided) => ({
    ...provided,
    backgroundColor: "#0e1219",
    borderColor: "#384454",
  }),
  valueContainer: (provided) => ({
    ...provided,
    backgroundColor: "#0e1219",
    borderColor: "#384454",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition, color: "#fff" };
  },
};

export default function EFLRightSideBar({
  game,
  updateGame,
  previewGraphic,
  graphicInPreview,
  animateGraphic,
  animateOffGraphic,
  updateGraphic,
  teams,
  selectedPlayer,
  players,
  stadiums,
  sky,
  squads,
  countries,
  standings,
  kits,
  clearPreview,
  homeManager,
  awayManager,
  groups,
  group,
  optaEvents,
  selectedSubPlayer,
  allOptaEvents,
}) {
  const { user, signOut } = useContext(UserContext);
  const { engines, selectedEngine, setSelectedEngine } =
    useContext(ControlContext);
  const { periods, homeScorers, awayScorers, homePenalties, awayPenalties } =
    useContext(UIContext);
  const { getFixtures, getStandings } = useContext(APIContext);
  const [view, setView] = useState(0);
  const { processedFirstEvent, processedSecondEvent } =
    useContext(GameEventsContext);
  let period = {};
  if (periods.length > 0) {
    period = periods?.sort((a, b) => b.number - a.number)?.[0];
  }
  let latestGoal =
    game && game.live_data?.home_scorers ? game.live_data?.home_scorers : [];

  latestGoal = latestGoal
    .concat(game.live_data?.away_scorers || [])
    .sort((a, b) => b.time - a.time);
  if (latestGoal.length > 0) {
    latestGoal = latestGoal[0].time;
  }
  let time = moment.duration(latestGoal);
  time =
    time.minutes() +
    time.hours() * 60 +
    ":" +
    time.seconds().toString().padStart(2, "0");

  return (
    <Main>
      {!window.hideHeader && (
        <Profile>
          {user?.username}
          <Button
            style={{ border: "1px solid #fff", marginLeft: "auto" }}
            onClick={() => {
              signOut();
            }}
          >
            Sign Out
          </Button>
        </Profile>
      )}
      {engines?.length > 1 && (
        <SelectContainer
          styles={DropdownStyles}
          setSize={true}
          value={{
            value: selectedEngine,
            label: engines?.find((e) => e.key === selectedEngine)?.name,
          }}
          options={engines.map((item, index) => {
            return {
              value: item.key,
              label: item.name,
            };
          })}
          onChange={(e) => {
            setSelectedEngine(e.value);
          }}
          placeholder="Round"
        />
      )}
      <Logo src={HyperLogo}></Logo>
      <EFLClock
        game={game}
        teams={teams}
        updateGame={updateGame}
        previewGraphic={previewGraphic}
        animateGraphic={animateGraphic}
        animateOffGraphic={animateOffGraphic}
        updateGraphic={updateGraphic}
        sky={sky}
      />
      <Buttons>
        {/* <NavButton selected={view === 0} onClick={() => setView(0)}>
          MAIN
        </NavButton> */}
        {/* <NavButton selected={view === 1} onClick={() => setView(1)}>
          SUBS
        </NavButton> */}
      </Buttons>
      <Scroll>
        {selectedPlayer && view === 0 && (
          <EFLPlayer
            player={selectedPlayer}
            game={game}
            previewGraphic={previewGraphic}
            teams={teams}
            sky={sky}
            countries={countries}
            period={period}
            selectedSubPlayer={selectedSubPlayer}
            allOptaEvents={allOptaEvents}
          />
        )}
        {view === 1 &&
          (game.lineup?.home?.filter((p) => p.sub_order).length > 0 ||
            game.lineup?.away?.filter((p) => p.sub_order).length > 0) && (
            <Subs
              sky={sky}
              countries={countries}
              previewGraphic={previewGraphic}
              game={game}
              teams={teams}
              players={players}
              homeSubs={game.lineup?.home?.filter((p) => p.sub_order)}
              awaySubs={game.lineup?.away?.filter((p) => p.sub_order)}
            />
          )}
        {view === 0 && (
          <>
            {!selectedPlayer && !period?.kick_off && period?.number === 1 && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("MatchID", {
                    game: game,
                    teams: teams,
                    stadiums: stadiums,

                    group,
                  });
                }}
              >
                Match ID
              </GraphicButton>
            )}
            {!selectedPlayer && !period?.kick_off && period?.number === 1 && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("Formation", {
                    game: game,
                    teams: teams,
                    players: players,
                    team: game.home_team,
                    squads: game.lineup?.home,
                    formation: game.home_formation,
                    manager: homeManager,
                  });
                }}
              >
                Home Formation
              </GraphicButton>
            )}

            {!selectedPlayer && !period?.kick_off && period?.number === 1 && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("Formation", {
                    game: game,
                    teams: teams,
                    players: players,
                    team: game.away_team,
                    squads: game.lineup?.away,
                    formation: game.away_formation,
                    manager: awayManager,
                  });
                }}
              >
                Away Formation
              </GraphicButton>
            )}
            {!selectedPlayer && !period?.kick_off && period?.number === 1 && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("Lineup", {
                    game: game,
                    teams: teams,
                    players: players,
                    team: game.home_team,
                    squads: game.lineup?.home,
                    formation: game.home_formation,
                    manager: homeManager,
                  });
                }}
              >
                Home Lineup
              </GraphicButton>
            )}
            {!selectedPlayer && !period?.kick_off && period?.number === 1 && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("Lineup", {
                    game: game,
                    teams: teams,
                    players: players,
                    team: game.away_team,
                    squads: game.lineup?.away,
                    formation: game.away_formation,
                    manager: awayManager,
                  });
                }}
              >
                Away Lineup
              </GraphicButton>
            )}
              {/* {
            !selectedPlayer &&period?.kick_off && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("TeamTicker", {
                    game: game,
                    teams: teams,
                    players: players,
                    team: game.home_team,
                    squads: game.lineup?.home,
                    start: true,
                  });
                }}
              >
                Home Team Ticker
              </GraphicButton>
            )}

          {
            !selectedPlayer && period?.kick_off && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("TeamTicker", {
                    game: game,
                    teams: teams,
                    players: players,
                    team: game.away_team,
                    squads: game.lineup?.away,
                    start: true,
                  });
                }}
              >
                Away Team Ticker
              </GraphicButton>
            )} */}

            {!selectedPlayer && period && period.number === 1 && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("Versus", {
                    game: game,
                    teams: teams,

                    group,
                  });
                }}
              >
                Versus
              </GraphicButton>
            )}
            {!selectedPlayer && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("Score", {
                    game: {
                      ...game,
                      home_scorers: homeScorers,
                      away_scorers: awayScorers,
                    },
                    teams: teams,
                    optaEvents,
                    half: 2,
                    show_half: false,
                    group,
                  });
                }}
              >
                Match Score
              </GraphicButton>
            )}
            {!selectedPlayer && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("Score", {
                    game: {
                      ...game,
                      home_scorers: homeScorers,
                      away_scorers: awayScorers,
                    },
                    teams: teams,
                    optaEvents,
                    half: 2,
                    show_half: false,
                    group,
                    no_scorers: true,
                  });
                }}
              >
                Match Score (No Scorers)
              </GraphicButton>
            )}
            {!selectedPlayer && period?.kick_off && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("LTMatchStats", {
                    game: {
                      ...game,
                      home_scorers: homeScorers,
                      away_scorers: awayScorers,
                    },
                  });
                }}
              >
                LT Match Stats
              </GraphicButton>
            )}
            {!selectedPlayer && period?.kick_off && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("LTActionAreas", {
                    game: {
                      ...game,
                      home_scorers: homeScorers,
                      away_scorers: awayScorers,
                    },
                    period,
                    events:
                      period.number === 1
                        ? processedFirstEvent
                        : processedSecondEvent,
                  });
                }}
              >
                LT Action Areas
              </GraphicButton>
            )}
            {!selectedPlayer && period?.kick_off && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("LTZonesOfControl", {
                    game: {
                      ...game,
                      home_scorers: homeScorers,
                      away_scorers: awayScorers,
                    },
                    period,
                    events:
                      period.number === 1
                        ? processedFirstEvent
                        : processedSecondEvent,
                  });
                }}
              >
                LT Zones of Control
              </GraphicButton>
            )}

            {!selectedPlayer && period?.kick_off && (
              <GraphicButton
                onClick={async () => {
                  let standings = await getStandings({
                    season: game?.season?._id,
                    date: game.date?.replace("Z", ""),
                  });

                  previewGraphic("MiniStandings", {
                    game,
                    standings,
                    type: "TOP",
                  });
                }}
              >
                Mini Standings Top
              </GraphicButton>
            )}
            {!selectedPlayer && period?.kick_off && (
              <GraphicButton
                onClick={async () => {
                  let standings = await getStandings({
                    season: game?.season?._id,
                    date: game.date?.replace("Z", ""),
                  });

                  previewGraphic("MiniStandings", {
                    game,
                    standings,
                    type: "BOTTOM",
                  });
                }}
              >
                Mini Standings Bottom
              </GraphicButton>
            )}
          </>
        )}
        {!selectedPlayer && period?.number === 2 && !period?.kick_off && (
          <GraphicButton
            onClick={() => {
              previewGraphic("Score", {
                game: {
                  ...game,
                  home_scorers: homeScorers,
                  away_scorers: awayScorers,
                },
                type: "HT",
                group,
                period,
                optaEvents,
              });
            }}
          >
            HT Score
          </GraphicButton>
        )}
        {!selectedPlayer && period?.number === 2 && !period?.kick_off && (
          <GraphicButton
            onClick={() => {
              previewGraphic("Score", {
                game: {
                  ...game,
                  home_scorers: homeScorers,
                  away_scorers: awayScorers,
                },
                type: "HT",
                group,
                period,
                optaEvents,
                no_scorers: true,
              });
            }}
          >
            HT Score (No Scorers)
          </GraphicButton>
        )}
        {!selectedPlayer &&
          period?.number === 2 &&
          !period?.kick_off &&
          game?.opta_team_stats?.home && (
            <GraphicButton
              onClick={() => {
                previewGraphic("FFStats", {
                  game: {
                    ...game,
                    home_scorers: homeScorers,
                    away_scorers: awayScorers,
                  },
                  half: 1,
                });
              }}
            >
              HT Stats
            </GraphicButton>
          )}
        {!selectedPlayer && period?.number === 2 && !period?.kick_off && (
          <GraphicButton
            onClick={() => {
              previewGraphic("HT/FT Bug", {
                game: {
                  ...game,
                  home_scorers: homeScorers,
                  away_scorers: awayScorers,
                },
                type: "HT",
                group,
                period,
                optaEvents,
              });
            }}
          >
            HT Bug
          </GraphicButton>
        )}
        {!selectedPlayer && period?.number > 2 && (
          <GraphicButton
            onClick={() => {
              previewGraphic("Score", {
                game: {
                  ...game,
                  home_scorers: homeScorers,
                  away_scorers: awayScorers,
                },
                type: "FT",
                group,
                period,
                optaEvents,
              });
            }}
          >
            FT Score
          </GraphicButton>
        )}
        {!selectedPlayer && period?.number > 2 && (
          <GraphicButton
            onClick={() => {
              previewGraphic("Score", {
                game: {
                  ...game,
                  home_scorers: homeScorers,
                  away_scorers: awayScorers,
                },
                type: "FT",
                group,
                period,
                optaEvents,
                no_scorers: true,
              });
            }}
          >
            FT Score (No Scorers)
          </GraphicButton>
        )}
        {!selectedPlayer && period?.number > 2 && (
          <GraphicButton
            onClick={() => {
              previewGraphic("HT/FT Bug", {
                game: {
                  ...game,
                  home_scorers: homeScorers,
                  away_scorers: awayScorers,
                },
                type: "FT",
                group,
                period,
                optaEvents,
                no_scorers: true,
              });
            }}
          >
            FT Bug
          </GraphicButton>
        )}
        {!selectedPlayer &&
          (homePenalties?.length > 0 || awayPenalties?.length > 0) && (
            <GraphicButton
              onClick={() => {
                previewGraphic("Score", {
                  game: {
                    ...game,
                    home_scorers: homeScorers,
                    away_scorers: awayScorers,
                    home_penalties: homePenalties,
                    away_penalties: awayPenalties,
                  },
                  type: "PENS",
                  group,
                  period,
                });
              }}
            >
              Score After Penalties
            </GraphicButton>
          )}
        {!selectedPlayer &&
          period?.number > 2 &&
          !period?.kick_off &&
          game?.opta_team_stats?.home && (
            <GraphicButton
              onClick={() => {
                previewGraphic("FFStats", {
                  game: {
                    ...game,
                    home_scorers: homeScorers,
                    away_scorers: awayScorers,
                  },
                  half: 2,
                });
              }}
            >
              FT Stats
            </GraphicButton>
          )}
        {!selectedPlayer && !period?.kick_off && (
          <GraphicButton
            onClick={async () => {
              let fixtures = await getFixtures({
                date: game.date,
              });

              fixtures = fixtures?.filter(
                (f) => f?.competition?._id === game?.competition?._id
              );
              previewGraphic("Fixtures", {
                fixtures,
                game,
              });
            }}
          >
            Fixtures/Results
          </GraphicButton>
        )}
        {!selectedPlayer && !period?.kick_off && (
          <GraphicButton
            onClick={async () => {
              let standings = await getStandings({
                season: game?.season?._id,
                date: game.date?.replace("Z", ""),
              });

              previewGraphic("Standings", {
                game,
                standings,
              });
            }}
          >
            Standings
          </GraphicButton>
        )}
      </Scroll>
    </Main>
  );
}
